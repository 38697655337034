import { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import {
  messageUpvoteDownvote,
} from "../../../data/store/botSlice"

import { IFRAME_EVENT_TYPE, MESSAGE_READ_STATUS, MESSAGE_SENDER } from "../../../data/configs/constants";
import { cn, copyToClipboard, sendUpdateToParent } from "../../../data/configs/utils";
import OriTooltip from "../Custom/OriTooltip"
import OriIconButton from "../Custom/OriIconButton"
import OriButton from "../Custom/OriButton"

const MessageWrapper = ({
  id,
  scrollableContainerNode,
  readStatus,
  trainingDisabled,
  chatLogId,
  isLast,
  isNewUserMsg,
  sender,
  timestamp,
  status,
  flag,
  senderInfo,
  markingFlag,
  customerVote,
  children,
  onRetry,
  notification,
  onClickFlag,
  failureDetails,
  onMessageSeen,
  showEditAndTrain = false,
  editAndTrainPayload,
  handleBrainClicked
}) => {
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const scrollToRef = useRef(null)
  const tenantId = useSelector((state) => state.botDetails.tenantId)
  const avatarUrl = useSelector((state) => state.themeDetails.avatarUrl)
  const bubbleShape = useSelector((state) => state.themeDetails.bubbleShape)
  const themeMode = useSelector((state) => state.themeDetails.themeMode)
  const isTestBot = useSelector((state) => state.botDetails.isTestBot)
  const direction = useSelector((state) => state.themeDetails.direction)
  const sourceChatLogId = useSelector(
    (state) => state.botDetails.knowledgeSource?.chatLogId
  )
  const [ratingLoading, setRatingLoading] = useState(false)

  useEffect(() => {
    let observer = null

    if (
      !notification &&
      (sender === MESSAGE_SENDER.AGENT || sender === MESSAGE_SENDER.CHATBOT) &&
      id &&
      onMessageSeen &&
      readStatus === MESSAGE_READ_STATUS.DELIVERED
    )
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting)
              onMessageSeen(id, MESSAGE_READ_STATUS.SEEN)
          })
        },
        {
          root: document.getElementById(scrollableContainerNode) || null,
          threshold: 0.5,
        }
      )

    const node = containerRef.current
    if (node && observer) observer.observe(node)

    return () => {
      if (node && observer) observer.unobserve(node)
    }
  }, [
    id,
    notification,
    onMessageSeen,
    readStatus,
    scrollableContainerNode,
    sender,
  ])

  useEffect(() => {
    if (scrollToRef.current && isLast === true) {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
    }
  }, [children, isLast])

  const handleMessageRating = (value) => {
    const rating =
      value === "upvote"
        ? customerVote === "upvote"
          ? ""
          : "upvote"
        : customerVote === "downvote"
          ? ""
          : "downvote"
    setRatingLoading(true)
    dispatch(
      messageUpvoteDownvote({
        tenantId,
        chatLogId,
        messageId: id,
        customerVote: rating,
      })
    )
      .unwrap()
      .finally(() => {
        setRatingLoading(false)
      })
  }

  if (sender === MESSAGE_SENDER.CHATBOT || sender === MESSAGE_SENDER.AGENT)
    return (
      <>
        <div
          id={id}
          ref={containerRef}
          className={cn(
            "HoverableWrapper Animated FadeIn relative my-4 flex",
            notification ? "flex-row-reverse pl-0 pr-0" : "flex-row pl-2 pr-8",
          )}
        >
          <div
            className={cn(
              'relative p-4 pb-6 min-w-[100px] bg-botChatBubble text-botChatBubbleContrast border shadow-inner rounded-md',
              notification ? 'max-w-full bg-white' : 'max-w-[95%]',
              bubbleShape === "cornered" && 'rounded-3xl rounded-bl-md',
              bubbleShape === "rounded" && 'rounded-3xl',
            )}>
            {!notification && isTestBot && (
              <div
                className={cn(
                  "absolute flex top-0 mx-0.5",
                  direction === "rtl" ? "right-full" : "left-full"
                )}
              >
                {sourceChatLogId && handleBrainClicked && chatLogId === sourceChatLogId && (
                  <OriTooltip label={'See knowledge source'} className="right-0 left-auto translate-x-0">
                    <OriIconButton
                      aria-label="redirect"
                      size="large"
                      variant="text"
                      className="Animated FadeIn text-gray-500"
                      onClick={handleBrainClicked}>
                      <i class="ri-brain-2-line"></i>
                    </OriIconButton>
                  </OriTooltip>
                )}
              </div>
            )}
            {children}
            {failureDetails && (
              <div className="p-3 bg-orange-50 flex items-center gap-2 rounded-md my-1">
                <p className="text-orange-800">
                  ({failureDetails?.code}) {failureDetails?.internalMessage}
                </p>
                <OriIconButton variant='text' className="h-6 text-orange-800" onClick={() => copyToClipboard(`(${failureDetails?.code}) ${failureDetails?.internalMessage}`)}>
                  <i class="ri-file-copy-line"></i>
                </OriIconButton>
              </div>
            )}
            {showEditAndTrain && id && chatLogId && (
              <OriButton
                size='small'
                className="my-2 block mx-auto"
                onClick={() => {
                  onClickFlag()
                  sendUpdateToParent(IFRAME_EVENT_TYPE.TRAIN_AND_TEST_CLICKED, editAndTrainPayload)
                }}>
                Edit and Train
              </OriButton>
            )}
            {sender === MESSAGE_SENDER.CHATBOT && id && chatLogId && (
              <div className="Animated FadeIn WrapperHoverHidden z-10 bg-background rounded-lg shadow-xl border absolute right-2 bottom-0 translate-y-1/2 px-2 flex gap-2 justify-end">
                <OriIconButton
                  aria-label="thumsup"
                  disabled={ratingLoading}
                  onClick={() => handleMessageRating("upvote")}
                  className={cn("h-6", customerVote === "downvote" && "text-gray-400 ")}
                  variant="text"
                >
                  {customerVote === "upvote" ? (
                    <i class="ri-thumb-up-fill"></i>
                  ) : (
                    <i class="ri-thumb-up-line"></i>
                  )}
                </OriIconButton>
                <OriIconButton
                  aria-label="thumsdown"
                  disabled={ratingLoading}
                  onClick={() => handleMessageRating("downvote")}
                  className={cn("h-6", customerVote === "upvote" && "text-gray-400 ")}
                  variant="text"
                >
                  {customerVote === "downvote" ? (
                    <i class="ri-thumb-down-fill"></i>
                  ) : (
                    <i class="ri-thumb-down-line"></i>
                  )}
                </OriIconButton>
              </div>
            )}
            {timestamp && (
              <div className="Animated FadeIn WrapperHoverHidden absolute left-1 bottom-1 px-3 flex justify-end">
                <p className="text-[10px] text-gray-500">
                  {new Date(timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </div>
            )}
          </div>
        </div>
      {isLast && (
        <div ref={scrollToRef}/>
      )}
      </>
    )
  else if (!notification && sender === MESSAGE_SENDER.USER)
    return (
      <div
        ref={containerRef}
        className={cn(
          "Animated FadeIn flex flex-row-reverse px-2 my-4",
        )}
      >
        <div
          className={cn(
            "HoverableWrapper relative p-4 bg-userChatBubble text-userChatBubbleContrast shadow-xl min-w-[100px] max-w-[95%] rounded-md",
            bubbleShape === "cornered" && 'rounded-3xl rounded-br-md',
            bubbleShape === "rounded" && 'rounded-3xl',
          )}
        >
          {children}
          <div className={cn(
            'Animated FadeIn absolute right-0 bottom-0.5 px-2 flex justify-end',
            isNewUserMsg || status === "failed" || status === "pending" ? "" : "WrapperHoverHidden",
          )}>
            {status === "failed" && (
              <p className="text-xs mx-1 cursor-pointer hover:underline" onClick={onRetry}>
                Retry
              </p>
            )}
            <p className="text-xs mr-1">
              {status === "pending" && (
                <i class="ri-time-line"></i>
              )}
              {/* {new Date(timestamp).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} */}
            </p>
          </div>
        </div>
      </div>
    )
  return null
}

MessageWrapper.propTypes = {
  id: PropTypes.string,
  scrollableContainerNode: PropTypes.string,
  trainingDisabled: PropTypes.bool,
  isLast: PropTypes.bool,
  isNewUserMsg: PropTypes.bool,
  sender: PropTypes.string,
  timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRetry: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  notification: PropTypes.bool,
  flag: PropTypes.bool,
  markingFlag: PropTypes.bool,
  senderInfo: PropTypes.object,
  readStatus: PropTypes.string,
  onMessageSeen: PropTypes.func,
}

MessageWrapper.defaultProps = {
  status: null,
  isLast: false,
  isNewUserMsg: false,
  notification: false,
  flag: false,
  markingFlag: false,
  trainingDisabled: false,
  senderInfo: {},
  readStatus: "",
  scrollableContainerNode: "chatbotBody",
}

export default MessageWrapper
